import { CommodityDefaultSpecifications } from '@hectare/platform.clients.system'

import { ApiInventory } from '~/lib/api-inventory'
import { composeSearchParams } from '~/lib/utility/url'

export class CommodityService extends ApiInventory {
  getDefaultSpecifications(
    commodityId: number,
    commodityTypeId?: number,
    commodityGradeId?: number
  ): Promise<CommodityDefaultSpecifications> {
    const query = composeSearchParams({
      commodityType: commodityTypeId,
      commodityGrade: commodityGradeId
    })

    const hasQueryParams = typeof commodityTypeId !== 'undefined' || typeof commodityGradeId !== 'undefined'

    return this.get(
      `/system/commodities/${commodityId}/specifications${hasQueryParams ? `?${query.toString()}` : ''}`
    )
  }
}

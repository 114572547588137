import { useStore } from '@nuxtjs/composition-api'

import { modulesMap, permissionsMap } from '~/lib/enums/permissions.config'

export default function useUserPermissions() {
  const store = useStore()

  function getHasPermission(permissions) {
    return store.getters['inventory/auth/getHasPermission'](permissions)
  }

  function getHasModule(moduleId) {
    return store.getters['inventory/auth/getHasModule'](moduleId)
  }

  return {
    getHasPermission,
    getHasModule,
    permissionsMap,
    modulesMap
  }
}

import posthogJs, { Properties } from 'posthog-js'

import { resolveEnvironmentConfig } from '~/lib/config'

const {
  posthog: { apiToken, apiHost, proxyHost, analyticsEnabled }
} = resolveEnvironmentConfig()

let properties: Properties | undefined

const listMissingCredentials = () => {
  const missingCreds = Object.entries({
    POSTHOG_API_HOST: apiHost,
    POSTHOG_PROXY_HOST: proxyHost,
    POSTHOG_API_TOKEN: apiToken
  })
    .filter(([, value]) => !value)
    .map(([key]) => key)

  return missingCreds
}

export const capturePageview = (toPath: string, fromPath?: string) => {
  if (!analyticsEnabled) return

  if (fromPath) {
    posthogJs?.capture('$pageleave', { ...(properties ?? {}), ...{ current_url: fromPath } })
  }

  posthogJs?.capture('$pageview', { ...(properties ?? {}), ...{ current_url: toPath } })
}

if (!apiToken || !apiHost || !proxyHost) {
  const missingCreds = listMissingCredentials()

  console.error(`Cannot initialise PostHog, missing API credentials ${missingCreds}`)
} else {
  posthogJs.init(apiToken, {
    api_host: proxyHost,
    ui_host: apiHost,
    autocapture: analyticsEnabled,
    person_profiles: 'always',
    capture_performance: analyticsEnabled,
    capture_pageview: false,
    capture_pageleave: false,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true
      }
    }
  })

  const initialFlagsJsonString = JSON.stringify(posthogJs.featureFlags.getFlags())

  posthogJs.onFeatureFlags(function () {
    const newFlagsJsonString = JSON.stringify(posthogJs.featureFlags.getFlags())

    if (initialFlagsJsonString !== newFlagsJsonString) {
      location.reload()
    }
  })

  posthogJs.reloadFeatureFlags()
}

export const captureEvent = (payload: Properties) => {
  if (!analyticsEnabled) return

  const { event, ...props } = payload

  if (!event) return

  posthogJs?.capture(event, { ...(properties ?? {}), ...(props ?? {}) })
}

export const identifyUser = (uid: string, email: string, organisation: string) => {
  if (!analyticsEnabled) return

  posthogJs?.identify(uid, { email, organisation, uid })
}

export const clearDefaults = () => {
  properties = undefined
}

export const setDefaults = (props: Properties) => {
  properties = props
}

export const setDataFields = (props: Properties) => {
  properties = { ...(properties ?? {}), ...(props ?? {}) }
}

export const flagPayloads = posthogJs.featureFlags.getFlagPayloads()

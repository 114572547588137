// User profile'access' permissions paths
export const permissionsMap = {
  admin: 'administrator',

  viewAccount: 'account.account.read',
  writeAccount: 'account.account.write',

  viewBusiness: 'account.business.read',
  writeBusiness: 'account.business.write',

  viewTeams: 'account.teams.read',
  writeTeams: 'account.teams.write',

  // Currently only owners have so can use to block by owners only:
  viewBusinessUnits: 'account.business-units.read',
  writeBusinessUnits: 'account.business-units.write',

  viewInventoryMovements: 'inventory.movements.read',
  writeInventoryMovements: 'inventory.movements.write',

  viewTradingContracts: 'trading.contracts.read',
  writeTradingContracts: 'trading.contracts.write',

  viewTradingListings: 'trading.listings.read',
  writeTradingListings: 'trading.listings.write',

  viewTradingPosition: 'trading.position.read',

  viewTradingBids: 'trading.bids.read',
  writeTradingBids: 'trading.bids.write',

  viewTradingInsightsGrain: 'trading.insights.grain',
  viewTradingInsightsLivestock: 'trading.insights.livestock',

  viewTradingMarket: 'trading.market.read',

  viewLogisticsCarrier: 'logistics.carrier.read',
  writeLogisticsCarrier: 'logistics.carrier.write'
}

export const modulesMap = {
  inventoryCore: 'inventory:core',
  tradingCore: 'trading:core',
  tradingInsightsGrain: 'trading:insights:grain',
  tradingBuyers: 'trading:buyers'
}

import { Device } from '@capacitor/device'
import { createFetchHandler } from '@hectare/api-client'
import { ApiClientError, FetchHandler } from '@hectare/api-client/src/fetchHandler'
import { computed, useContext, useRoute, useRouter } from '@nuxtjs/composition-api'

import { offlineDataAuth } from '~/lib/enums/cookies/offline-data-keys'
import date from '~/lib/utility/date'
import { getOfflineStorage } from '~/lib/utility/offline-storage'

import { resolveApiBaseUri } from '../config'

interface ApiClientResponse<T> {
  status: number
  data: T
}

interface SelectedOrganisation {
  id: string
  name: string
}

export const maxRetries = 5

const useApiClient = () => {
  const {
    store,
    $log,
    $config: { apiBaseUrlInventory }
  } = useContext()

  const route = useRoute()
  const router = useRouter()

  const accessToken = computed<string>(() => store.state.inventory.auth.accessToken)
  const idToken = computed<string>(() => store.state.inventory.auth.idToken)
  const impersonation = computed<string>(() => store.state.inventory.auth.impersonateBusiness?.token)

  const selectedOrganisation = computed<SelectedOrganisation | undefined>(() => {
    const storedSelectedOrg = getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_SELECTED_ORG)
    let organisation: SelectedOrganisation | undefined

    if (storedSelectedOrg) {
      organisation = JSON.parse(storedSelectedOrg)
    }

    return organisation
  })

  const createFetchHandlerProxy: FetchHandler = async <T>(...args: Parameters<FetchHandler>) => {
    const maxRetries = 5
    let retries = 0
    const isAuthPath = route.value.path.includes('/inventory/auth')
    const isAuth = getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ACCESS_TOKEN)

    const attemptFetch = async (): Promise<ApiClientResponse<T>> => {
      try {
        if (!isAuth && !isAuthPath) {
          await store.dispatch('inventory/auth/logout')
          return Promise.reject(new Error('No auth tokens found'))
        }

        const deviceInfo = await Device.getInfo()

        if (!isAuthPath) {
          await store.dispatch('inventory/auth/refreshTokenIfNeeded')
        }

        const fetchHandler = createFetchHandler({
          apiUrl: resolveApiBaseUri(apiBaseUrlInventory),
          accessToken: accessToken.value,
          idToken: idToken.value,
          deviceInfo: `${deviceInfo.platform}, ${deviceInfo.operatingSystem}, ${deviceInfo.osVersion}`,
          timezoneOffset: `${date().utcOffset()}`,
          ...(selectedOrganisation.value?.id &&
            selectedOrganisation.value?.name && {
              organisation: {
                id: selectedOrganisation.value?.id,
                name: selectedOrganisation.value?.name
              }
            }),
          ...(impersonation.value && { impersonation: impersonation.value })
        })

        const response = await fetchHandler<T>(...args)

        return response
      } catch (error) {
        const errorIsApiClientError = (error: unknown): error is ApiClientError => {
          return !!error && typeof error === 'object' && 'status' in error
        }

        if (errorIsApiClientError(error)) {
          if (error.status === 401 && retries < maxRetries && isAuth) {
            retries++

            if (!isAuthPath) {
              await store.dispatch('inventory/auth/refreshTokenIfNeeded')
            }

            $log.error(error.data?.message ?? error.data?.validation?.[0]?.message, error.data)

            return attemptFetch()
          }

          if (error.status === 440) {
            await store.dispatch('inventory/auth/endImpersonateBusiness', { getUserProfile: !!isAuth })
            const getHasRole = store.getters['inventory/auth/getHasRole']

            if (
              getHasRole('sys-admin') &&
              !route.value.path.includes('/inventory/internal/business-accounts') &&
              getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_ACCESS_TOKEN)
            ) {
              router.push('/inventory/internal/business-accounts')
            }
          }
        } else {
          $log.error('received non api client error', error)
        }

        throw error
      }
    }

    return await attemptFetch()
  }

  return { fetchHandler: createFetchHandlerProxy }
}

export default useApiClient

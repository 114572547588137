import { ApiInventory } from '~/lib/api-inventory'
import { resolveApiBaseUri } from '~/lib/config'
import { stateConfig } from '~/lib/search/search-initial-state'

export class SearchService extends ApiInventory {
  search(lastCalledApiPath = null, stateKey, additionalSearchParams) {
    const apiHost = stateConfig[stateKey].host

    // Some pages call shared endpoints to get the required information for the page
    // having their own stateKey scopes the search params/facets for that UI
    const apiSearchPath = stateConfig[stateKey].aliasApiPath ?? stateKey

    let searchParams = ''

    if (lastCalledApiPath) {
      // Remove limit and offset from string to get hold of facets
      const url = new URL(`${resolveApiBaseUri(this.nuxt.$config.apiBaseUrlInventory)}${lastCalledApiPath}`)
      const searchParamsObj = Object.fromEntries(url.searchParams.entries())

      // Remove any duplicate keys
      const merged = Object.assign({}, searchParamsObj, additionalSearchParams)

      searchParams = new URLSearchParams(merged)
    } else {
      searchParams = new URLSearchParams(additionalSearchParams)
    }

    try {
      this.state.response.params = Object.fromEntries(searchParams.entries())
      this.state.response.path = `/${apiHost}/${apiSearchPath}?${searchParams.toString()}`
      return this.get(`/${apiHost}/${apiSearchPath}?${searchParams.toString()}`)
    } catch (error) {
      this.$log.error(`Error calling /${apiHost}/${apiSearchPath}`, error)
    }
  }
}

import { flagPayloads } from '~/services/posthog'

export const getFlagPayload = (featureKey: string) => {
  return flagPayloads[featureKey]
}

export const hasShowUnsavedChangesModal = getFlagPayload('show-unsaved-changes-modal')
export const hasAutoEndV2 = getFlagPayload('auto-end-v2')
export const hasLoadingSkeletons = getFlagPayload('loading-skeletons')
export const hasDraftListings = getFlagPayload('draft-listings')
export const hasBuyerHideOrgToggle = getFlagPayload('buyer-hide-org-toggle')
export const hasAdvancePayCalculatorRefactor = getFlagPayload('advance-pay-calculator-refactor')
export const hasListingSupportComponent = getFlagPayload('listing-support-component')
export const hasCommoditySearch = getFlagPayload('commodity-fuzzy-search')
export const hasTargetPricesExplainer = getFlagPayload('target-prices-explainer')
export const hasTermsAndConditionsModalRefactor = getFlagPayload('terms-and-conditions-modal-refactor')
export const hasListingsRecentCommodities = getFlagPayload('listings-recent-commodities')
export const hasBuyerFormRedesign = getFlagPayload('buyer-form-redesign')
export const hasUpdateLoginImages = getFlagPayload('update-login-images')
export const hasTargetPricesCalculate = getFlagPayload('target-prices-calculate')
export const hasRegionSelectorAlertPreferences = getFlagPayload('region-selector-alert-preferences')
export const hasListingBasePlusPremiumOption = getFlagPayload('listings-base-plus-premium-option')
export const hasPasswordSecurityRulesUpdate = getFlagPayload('password-security-rules-update')
export const hasListingClosedPrompt = getFlagPayload('listing-closed-prompt')
export const hasBuyerListingsAllWanted = getFlagPayload('buyer-listings-all-wanted')
export const hasBuyerAlertBidSameOrg = getFlagPayload('buyer-alert-bid-same-org')
export const hasImpersonateUserLevel = getFlagPayload('impersonate-user-level')
export const hasClearerSpecificationChanges = getFlagPayload('clearer-specification-changes')
export const hasUpdateVarietySelection = getFlagPayload('update-variety-selection')
export const hasRequestToReopenPrice = getFlagPayload('request-to-reopen-bid')
export const hasHelpSectionUpdates = getFlagPayload('help-section-updates')
export const hasSignupCommodityPreferences = getFlagPayload('signup-commodity-preferences')
export const hasLatestUpdates = getFlagPayload('latest-updates-work')
export const hasRedesignTeamMembers = getFlagPayload('redesign-team-members')
export const hasUpdateTermsAndConditionsAllUsers = getFlagPayload('update-terms-and-conditions-all-users')
export const hasVarietyInputUpdate = getFlagPayload('variety-formatting-input-update')
export const hasPreviousPriceOffer = getFlagPayload('previous-price-offer')
export const hasPrivacyPolicyUpdate = getFlagPayload('update-privacy-policy')
export const hasRedesignWanteds = getFlagPayload('redesign-wanted-page')
export const hasPostedListingSpecificationView = getFlagPayload('posted-listing-specification-view')
export const hasOfferBidUiUpdates = getFlagPayload('seller-buyer-offer-bid-ui-updates')
export const hasMobileModalUiUpdate = getFlagPayload('mobile-modal-ui-updates')
export const hasRedTractorSelectMatch = getFlagPayload('red-tractor-select-match')
export const hasFarmLocations = getFlagPayload('farm-locations')
export const hasRecentTradesUpdates = getFlagPayload('recent-trades-updates')
export const hasBuyerAgreedTradeUpdates = getFlagPayload('buyer-agreed-trade-updates')
export const hasMarketInsightsUpdatesV1 = getFlagPayload('market-insights-updates-v1')
export const hasNewsExFarmUiUpdates = getFlagPayload('news-feed-ex-farm-ui-updates')

const appEnvironment = process.env.APP_ENVIRONMENT
const validFeatureFlagEnvionments = ['prod', 'uat', 'dev']

const featureFlagEnvironmentOverwrite = localStorage.getItem('featureFlagConfigOverwrite')

const posthog = {
  dev: {
    apiHost: 'https://eu.posthog.com',
    apiToken: 'phc_wuQil4j8INWCCJMOR5JIuEInczoNtUKKjMVKJmt1kXj',
    proxyHost: 'https://ph-dev.wearehectare.com',
    analyticsEnabled: false
  },
  uat: {
    apiHost: 'https://eu.posthog.com',
    apiToken: 'phc_t1i26jmF6AlSXvhHTe1rbC3n7H7BfJQrLR18QcIwPon',
    proxyHost: 'https://ph-uat.wearehectare.com',
    analyticsEnabled: false
  },
  prod: {
    apiHost: 'https://eu.posthog.com',
    apiToken: 'phc_jDp31Bjfj9RzqQvLmlBXegDwy1r3EJ2Y9jmqCj6glLF',
    proxyHost: 'https://ph.wearehectare.com',
    analyticsEnabled: !featureFlagEnvironmentOverwrite
  }
}

const featureFlagEnvironmentOverwriteIsValid = (
  environment: string | null
): environment is keyof typeof posthog => {
  return !!environment && validFeatureFlagEnvionments.includes(environment)
}

const resolveFeatureFlagEnvironment = (): keyof typeof posthog => {
  if (featureFlagEnvironmentOverwriteIsValid(featureFlagEnvironmentOverwrite) && appEnvironment !== 'production') {
    return featureFlagEnvironmentOverwrite
  }

  switch (appEnvironment) {
    case 'production':
      return 'prod'
    case 'uat':
      return 'uat'
    default:
      return 'dev'
  }
}

export const resolveApiBaseUri = (apiUri: string) => {
  const isPreviewEnvironment = window.location.host.startsWith('preview.')

  return isPreviewEnvironment ? apiUri.replace('dev', 'preview') : apiUri
}

export const resolveEnvironmentConfig = () => {
  const environment = resolveFeatureFlagEnvironment()

  return {
    posthog: posthog[environment]
  }
}
